import styled from "styled-components";
import {Tabs} from "antd";

const {TabPane} = Tabs;

const TabsStyled = styled(Tabs)`
  &.ant-tabs-bottom > .ant-tabs-nav:before, &.ant-tabs-bottom > div > .ant-tabs-nav:before, &.ant-tabs-top > .ant-tabs-nav:before, &.ant-tabs-top > div > .ant-tabs-nav:before {
    border-bottom: 1px solid #D9D8D8;
  }
  
  .ant-tabs-nav{
    margin: 0;
  }

  .ant-tabs-tab {
    font-size: 20px;
    color: #8E8A8A;
    padding-right: 48px;
    padding-left: 48px;

    .ant-tabs-tab-btn {
      &:active {
        color: #272424;
      }
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #272424;
      }
    }
  }

  @media (max-width: 576px) {
    .ant-tabs-tab {
      padding: 0;
      margin: 0 19px 0 0;
    }
  }

  .ant-tabs-ink-bar {
    background: #272424;
  }
`

const TabPaneStyled = styled(TabPane)``

export {TabsStyled, TabPaneStyled};