// Action types
import ICartStore from "../models/ICartStore";
import ITicket from "../models/ITicket";

export const types = {
    SET_TICKETS: "SET_TICKETS",
    SET_CART_SHOWING: "SET_CART_SHOWING",
};

// Initial state
export const initialState: ICartStore = {
    tickets: [],
    showCart: false
};

// Reducer
export default (state: ICartStore = initialState, action: any) => {
    switch (action.type) {
        case types.SET_TICKETS:
            return {...state, tickets: action.payload.tickets};
        case types.SET_CART_SHOWING:
            return {...state, showCart: action.payload.showCart};
        default:
            break
    }

    return state
}

// Actions

export const setTickets = (tickets: ITicket[]) => ({type: types.SET_TICKETS, payload: {tickets}});

export const setShowCart = (showCart: boolean) => ({type: types.SET_CART_SHOWING, payload: {showCart}});

// Selectors

export const getTickets = (state: any) => {
    return state.cart.tickets
};

export const getTicketsCount = (state: any) => {
    let allTicketQuantity = 0
    state.cart.tickets.forEach((ticket: ITicket) => allTicketQuantity += ticket.quantity)
    return allTicketQuantity
};

export const getCartShowing = (state: any) => {
    return state.cart.showCart
};
