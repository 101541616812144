import styled, { css } from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const baseStyles = css`
  border: 1px solid #272424;
  &:hover {
    border-color: #272424;
  }
  &.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(39, 36, 36, 0.1);
  }
  .ant-picker-active-bar {
    background: #272424;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  ${baseStyles}
`;
const RangePickerStyled = styled(RangePicker)`
  ${baseStyles}
`;

export { DatePickerStyled, RangePickerStyled };
