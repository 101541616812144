import React from 'react';
import styled from 'styled-components';
import moment from "moment";

import ITicket from '../../models/ITicket';

const TicketStyled = styled.div`
  cursor: pointer;
  
  &.disabled {
    opacity: 0.5;
  }

  .ticket-info {
    margin-top: 8px;
    font-size: 16px;
  }

  .ticket-cost {
    font-weight: bold;
  }
  
  .date-info {
    font-size: 12px;
    i {
      margin-right: 4px;
    }
  }
`;

interface Props {
    ticket: ITicket;
    ticketAddClick: (ticket: ITicket) => void;
    style?: React.CSSProperties
    isDisabled?: boolean;
}

export default function TicketButton({
                                         ticket,
                                         ticketAddClick,
                                         isDisabled,
                                         ...rest
                                     }: Props) {
    return (
        <TicketStyled {...rest} className={isDisabled ? 'disabled' : ''} onClick={() => {
            ticketAddClick(ticket)
        }}>
            <div className="picture">
                <img src={ticket.imageUrl ?? '/ticket.png'}
                     alt="tickets"
                     height='146px'
                     width='280px'
                     style={{objectFit: 'cover'}}/>
            </div>
            <div className="ticket-info">
                <div className="ticket-title">
                    {ticket.title}
                </div>
                <div className="date-info">
                    {ticket.sessionPeriod ?
                        <span>
                            <i className="las la-calendar"/>
                            {moment(ticket.sessionPeriod[0]).format('DD.MM.YYYY')} - {moment(ticket.sessionPeriod[1]).format('DD.MM.YYYY')}
                        </span>
                        : ''}
                </div>
                <div className="ticket-cost">
                    {ticket.cost}₴
                </div>
            </div>
        </TicketStyled>
    );
}

TicketButton.defaultProps = {
    isDisabled: false,
};
