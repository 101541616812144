import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Header from "./components/Header/Header";
import TicketsPage from "./components/TicketsPage/TicketsPage";
import TicketOrder from "./components/TicketOrder/TicketOrder";
import {Provider} from "react-redux";
import store from "./store";
import Cart from "./components/Cart/Cart";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Header/>
                <Cart/>
                <Switch>
                    <Route path="/ticket/:id">
                        <TicketOrder/>
                    </Route>
                    <Route path="/">
                        <TicketsPage/>
                    </Route>
                </Switch>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
