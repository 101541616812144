import styled from 'styled-components';
import { Select } from 'antd';

const SelectStyled = styled(Select)`
  border: 1px solid #272424;
  //&:not(.ant-select-disabled):hover {
  .ant-select-selector {
    border: none !important;
  }
  //}
`;

export default SelectStyled;
