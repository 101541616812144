import React from 'react';
import styled from 'styled-components';
import {Row} from 'antd';
import TicketButton from '../TicketButton/TicketButton';
import ITicket from '../../models/ITicket';
import {TabPaneStyled, TabsStyled} from '../../styled/Tabs/Tabs';

const TicketsStyled = styled.div`
  background: #ffffff;
  padding: 0 16px 16px 16px;
  overflow: auto;
  
  .tab-pane {
    font-size: 20px;
  }

  .tickets {
    margin-top: 24px;

    .ticket-column {
      .title {
        font-size: 20px;
        font-weight: 500;
      }

      > div {
        &:not(:first-child) {
          margin-top: 16px;
        }

        &:last-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  .red {
    color: #ED7969;
  }

  .warnings {
    margin-top: 24px;
  }

  .warning {
    font-size: 14px;

    span {
      margin-right: 4px;
    }
  }
`;

interface Props {
    tickets: ITicket[] | undefined;
    addTicketToCart: (ticket: ITicket) => void;
    isExcursionsDisabled: boolean;
}

export default function Tickets({
                                    tickets,
                                    addTicketToCart,
                                    isExcursionsDisabled,
                                }: Props): JSX.Element {
    const renderEntryByCostType = (
        costType: 'full' | 'privilege' = 'full'
    ) => {
        if (!tickets) return
        const filteredByEntry = tickets.filter((ticket) => ticket.type === 'entry');
        const filteredByCostType = filteredByEntry.filter(
            (ticket) => ticket.costType === costType
        );
        return filteredByCostType.map((ticket) => {
            return (
                <TicketButton
                    style={{marginRight: '24px', marginBottom: '48px', maxWidth: '280px'}}
                    key={ticket.id}
                    ticket={ticket}
                    ticketAddClick={addTicketToCart}
                />
            );
        });
    };

    const renderExcursions = () => {
        if (!tickets) return
        const filteredByType = tickets.filter(
            (ticket) => ticket.type === 'excursion'
        );
        return filteredByType.map((ticket, index) => {
            return (
                <TicketButton
                    style={{marginRight: '24px', marginBottom: '48px', maxWidth: '280px'}}
                    key={ticket.id}
                    ticket={ticket}
                    ticketAddClick={addTicketToCart}
                    isDisabled={isExcursionsDisabled}
                />
            )
        });
    };

    return (
        <TicketsStyled>
            <TabsStyled defaultActiveKey="1">
                <TabPaneStyled tab="Виставки та події" key="1" className='tab-pane'>
                    <div className="tickets">
                        <Row>{renderEntryByCostType('full')}</Row>
                        <div className="warnings">
                            <Row>
                                <p className='warning'><span className="red">*</span>
                                -50% на вхідні квитки до музею та гроту, відвідування екскурсій і лекцій та екскурсійне обслуговування¹ мають²: 
                                <ul>
                                  <li>учні та учениці;</li>
                                  <li>студенти та студентки;</li>
                                  <li>пенсіонери та пенсіонерки;</li>
                                  <li>особи з інвалідністю.</li>
                                </ul>

                                </p>
                            </Row>
                            <Row>
                                <p className='warning'>
                                  &nbsp;&nbsp;&nbsp;&nbsp;Безкоштовний вхід до музею та -50% на вхідні квитки до гроту, відвідування екскурсій і лекцій та екскурсійне обслуговування мають²:
                                  <ul>
                                  <li>діти до 7 років;</li>
<li>студенти вищих навчальних закладів за спеціальностями 023 Образотворче мистецтво, декоративне мистецтво, реставрація, 026 Сценічне мистецтво, 027 Музеєзнавство, пам’яткознавство, 032 Історія та археологія, 033 Філософія, 034 Культурологія, 191 Архітектура та містобудування;</li>
<li>співробітники та співробітниці музеїв України;</li>
<li>члени та членки міжнародної ради музеїв ICOM;</li>
<li>члени та членки Національної спілки художників України;</li>
<li>члени та членки Національної спілки журналістів України;</li>
<li>власники та власниці Міжнародної професійної картки журналіста;</li>
<li>учасники та учасниці Сил оборони України (Збройні Сили України, а також інші утворені відповідно до законів України військові формування, правоохоронні та розвідувальні органи, органи спеціального призначення з правоохоронними функціями, на які Конституцією та законами України покладено функції із забезпечення оборони держави)</li>
<li>учасники та учасниці бойових дій, ветерани війни, особи з інвалідністю внаслідок війни</li>
<li>співробітники та співробітниці Державної служби надзвичайних ситуацій та аварійно-рятувальних служб;</li>
<li>співробітники та співробітниці Національної поліції України;</li>
<li>члени та членки родин військовослужбовців, що загинули при виконанні службових обов'язків;</li>
<li>члени та членки родин поліцейських та співробітників ДСНС, що загинули при виконанні службових обов'язків;</li>
<li>внутрішньо переміщені особи;</li>
<li>особи, які постраждали внаслідок Чорнобильської катастрофи, I та ІІ категорій;</li>
<li>члени та членки багатодітних сімей (неповнолітні діти та їхні батьки);</li>
<li>діти-сироти та діти, позбавлені батьківського піклування;</li>

                                  </ul>
                                </p>
                            </Row>
                            <Row>
                                <p className='warning'><span className="red">**</span>
                                З міркувань безпеки ви можете відвідати грот виключно у супроводі екскурсовода-співробітника чи екскурсоводки-співробітниці музею.</p>
                            </Row>
                            <Row>
                            <p className='warning'><span className="red">¹</span>
                            -50% на екскурсійне обслуговування для учнів, студентів, пенсіонерів і осіб з інвалідністю діє за умови, що не менше половини екскурсійної групи належить до перелічених категорій.
                            </p>
                            </Row>
                            <Row>
                            <p className='warning'><span className="red">²</span>
                            Пільги діють за умови пред’явлення оригіналу посвідчення, що засвідчує відповідний пільговий статус, або його нотаріально засвідченої копії.
                            </p>
                            </Row>
                        </div>
                    </div>
                </TabPaneStyled>
                <TabPaneStyled tab="Екскурсії" key="2" className='tab-pane'>
                    <div className="tickets">
                        <Row>{renderExcursions()}</Row>
                    </div>
                </TabPaneStyled>
            </TabsStyled>
        </TicketsStyled>
    );
}
