import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

interface Props {
  show: boolean;
  // eslint-disable-next-line react/require-default-props
  overlay?: boolean;
}

const LoaderStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.overlay {
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
  }
`;

function Loader({ overlay, show }: Props): JSX.Element {
  return (
    <>
      {show ? (
        <LoaderStyled className={overlay ? 'overlay' : ''}>
          <Spin size="large" />
        </LoaderStyled>
      ) : (
        ''
      )}
    </>
  );
}
export default Loader;
