import React from 'react';
import styled from 'styled-components';
import {Badge, Grid} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from 'react-router-dom';
import {getCartShowing, getTicketsCount, setShowCart} from "../../store/cart";
import {ScreenMap} from "antd/lib/_util/responsiveObserve";

const { useBreakpoint } = Grid;

interface HeaderStyledProps {
    breakpoint: ScreenMap
}

const HeaderStyled = styled.div<HeaderStyledProps>`
  background: #ffffff;
  width: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  a {
    color: #272424;
    opacity: 1;
  }

  i.las {
    font-size: 24px;
    cursor: pointer;

    &:not(.la-shopping-cart) {
      margin-right: 8px;
    }
    
  }

  .return {
   a {
     color: #272424;
     display: flex;
     height: 46px;
     align-items: center;
     justify-content: center;
     font-size: 16px;
   }
  }

  .control-panel {
    color: #272424;
    display: flex;
    align-items: center;
    font-size: 14px;

    .text {
      color: #272424;
      opacity: 1;
      display: ${(props) => props.breakpoint.xs ? 'none' : 'block'};
    }

    .text:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export default function Header(): JSX.Element {
    const totalTicketsCount = useSelector(getTicketsCount)
    const showCart = useSelector(getCartShowing)
    const dispatch = useDispatch();
    const location = useLocation();
    const breakpoint = useBreakpoint();

    const onCartClick = () => {
        dispatch(setShowCart(!showCart))
    }

    return (
        <HeaderStyled breakpoint={breakpoint}>
            {location.pathname === '/' ?
                <div className="img-container">
                    <Link to="/">
                        <img src="/logo_black.svg" width="90" height="46" alt="logo"/>
                    </Link>
                </div> :
                <div className="return">
                    <Link to="/">
                        <i className="las la-arrow-left"/>
                        Повернутися
                    </Link>
                </div>}
            <div className="control-panel">
                {location.pathname === '/' ? <>
                    <a href="tel:+380 48 737 38 70"><i className="las la-phone"/></a>
                    <a href="tel:+380 48 737 38 70" className="text">+380 48 737 38 70</a>
                    <a href="tel:+380 48 723 72 87" className="text">+380 48 723 72 87</a>
                    <a href="mailto:office@ofam.org.ua"><i className="las la-envelope"/></a>
                    <a href="mailto:office@ofam.org.ua" className="text">office@ofam.org.ua</a>
                </> : ''}
                <div className={"cart-badge-wrapper"} onClick={onCartClick}>
                    <Badge className={"cart-badge"}
                           count={totalTicketsCount}
                           size="small">
                        <i className="las la-shopping-cart"/>
                    </Badge>
                </div>
            </div>
        </HeaderStyled>
    );
}
