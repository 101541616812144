import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button, Form, Grid, Input, message, Tooltip} from "antd";
import * as _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import ITicket from '../../models/ITicket';
import {getCartShowing, getTickets, setShowCart, setTickets} from "../../store/cart";
import IOrder from "../../models/IOrder";
import ButtonStyled from "../../styled/Button/Button";
import {ScreenMap} from "antd/lib/_util/responsiveObserve";
import TicketType from '../../constants/TicketType.enum';

const { useBreakpoint } = Grid;

interface CartStyledProps {
    breakpoint: ScreenMap
}

const CartStyled = styled.div<CartStyledProps>`
  height: 100%;
  top: 0;
  width: ${(props) => props.breakpoint.xs ? '100%' : '523px'};
  position: fixed;
  z-index: 3;
  right: 0;
  background: #ffffff;
  box-shadow: -2px 6px 24px 0 rgba(53, 64, 83, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.8s;

  &.hidden {
    right: ${(props) => props.breakpoint.xs ? '-100%' : '-523px'};
  }

  .wrapper {
    height: 100%;
    margin: 24px ${(props) => props.breakpoint.xs ? '16px' : '32px'};
    overflow: auto;
  }

  .header {
    padding: 0 0 14px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    > i {
      font-size: 24px;
      cursor: pointer;
    }
  }

  .items {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: ${(props) => props.breakpoint.xs ? 'column' : 'row'};
      width: 100%;
      margin: 16px 0;

      > div {
        display: flex;
        align-items: flex-start;
      }
      
      .counter-wrapper {
        justify-content: ${(props) => props.breakpoint.xs ? 'space-between' : 'inherit'};
        width: ${(props) => props.breakpoint.xs ? '100%' : 'initial'};
        padding-left: ${(props) => props.breakpoint.xs ? '24px' : '0'};
        margin-top: ${(props) => props.breakpoint.xs ? '16px' : '0'};
      }

      .remove {
        font-size: 20px;
        margin-right: 8px;
        cursor: pointer;
      }

      .info {
        .title {
          font-weight: 500;
          font-size: 16px;
        }

        .second-line {
          color: #6a6262;
          display: flex;
        }
      }

      .counter {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90px;
        margin-top: -4px;

        > i {
          font-size: 28px;
          cursor: pointer;
          
          &.fill-icon {
            font-size: 18px;
            margin: 8px auto 0;
          }
        }

        .number {
          margin: 0 12px;
        }
      }

      .price {
        font-size: 18px;
        font-weight: 500;
        margin-left: 28px;
        min-width: 47px;
        width: 56px;
      }
      
      .time-info {
        margin-left: 10px;
        display: flex;
        align-items: center;
        i {
          font-size: 16px;
          margin-right: 2px;
        }
      }
    }
  }

  .footer {
    padding: 0 ${(props) => props.breakpoint.xs ? '16px' : '32px'} 32px;

    .summary {
      display: flex;
      justify-content: space-between;
      font-size: 18px;

      .summary-text {
        color: #6a6262;
      }

      .summary-price {
        color: #272424;
        font-weight: 500;
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
    }

    .apply-btn {
      border: none;
      padding: 12px 48px;
      height: inherit;
      font-weight: bold;
      width: ${(props) => props.breakpoint.xs ? '100%' : 'inherit'};
    }
  }

  .email-wrapper {
    margin: 32px 0;
    font-size: 14px;

    .email-text {
      margin-bottom: 8px;
      display: inline-block;
    }
  }

  .made-by {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    color: #6a6262;
  }

  a {
    color: #272424;
    opacity: 1;
    margin-left: 2px;
    cursor: pointer;
  }
  
  .empty-msg-wrapper {
    width: 100%;
    height: calc(100% - 38px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #272424;
    .empty-msg {
      opacity: 0.5;
      text-align: center;
      max-width: 325px;
    }
    .no-tickets-img {
      margin-top: 24px;
    }
  }
`;

export default function Cart(): JSX.Element {

    const [orderPrice, setOrderPrice] = useState<number>(0);
    const [orderId, setOrderId] = useState<string>('');
    const [orderSuccessURL, setOrderSuccessURL] = useState<string>('');
    const [orderFailureURL, setOrderFailureURL] = useState<string>('');

    const formRef = useRef(null);
    const selectedTickets = useSelector(getTickets)
    const showCart = useSelector(getCartShowing)
    const dispatch = useDispatch();
    const breakpoint = useBreakpoint();
    const [emailForm] = Form.useForm();

    /**
     * Hook that handle clicks outside of the passed ref
     */
    function useOutsideHandler(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (ref.current && !ref.current.contains(event.target)) {
                    dispatch(setShowCart(false))
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const cartRef = useRef<HTMLDivElement>(null);
    useOutsideHandler(cartRef);

    const removeSelectedTicket = (ticket: ITicket) => {
        const newSelectedTickets = [...selectedTickets];
        _.remove(
            newSelectedTickets,
            (t) =>
                t.id === ticket.id &&
                (t.sessionTime === undefined || t.sessionTime === ticket.sessionTime)
        );
        dispatch(setTickets(newSelectedTickets));
    };

    // const removeAllSelectedTickets = () => {
    //     dispatch(setTickets([]));
    // };

    const getSummaryPrice = () => {
        const summary = 0;
        if (selectedTickets.length === 0) return summary;
        return selectedTickets.reduce(
            (accumulator: number, currentValue: ITicket) =>
                accumulator + currentValue.cost * currentValue.quantity,
            summary
        );
    };

    const addTicketToCart = (ticket: ITicket) => {
        let sessionTime: any = ticket.sessionTime;
        let newSelectedTickets = [...selectedTickets];
        const isExistedTicket = selectedTickets.find(
            (t: ITicket) =>
                t.id === ticket.id &&
                (t.sessionTime === undefined || t.sessionTime === sessionTime)
        );
        if (isExistedTicket) {
            newSelectedTickets = newSelectedTickets.map((t) => {
                const newQuantity = t.quantity + 1;
                return {
                    ...t,
                    quantity:
                        t.id === ticket.id &&
                        (t.sessionTime === undefined || t.sessionTime === sessionTime)
                            ? newQuantity
                            : t.quantity,
                };
            });
        } else {
            const sessionParams: any = {};
            if (ticket.sessionPeriod) {
                if (
                    ticket.sessionSchedule &&
                    ticket.sessionSchedule[0]?.limit !== undefined
                ) {
                    const d = moment(sessionTime);
                    const schedule = ticket.sessionSchedule.find(
                        (sch) => sch.hour === d.hour() && sch.minutes === d.minute()
                    );
                    if (!schedule) return;
                    sessionParams.sessionLimit = schedule.limit;
                }
                if (
                    ticket.sessionSchedule?.length === 0 &&
                    ticket.limit !== undefined
                ) {
                    sessionParams.sessionLimit = ticket.limit;
                }
                sessionParams.sessionTime = sessionTime;
            }
            newSelectedTickets.push({ ...ticket, quantity: 1, ...sessionParams });
        }
        dispatch(setTickets(newSelectedTickets));
    };

    const minusTicket = (ticket: ITicket) => {
        const newQuantity = ticket.quantity - 1;
        let newSelectedTickets = [...selectedTickets];
        if (newQuantity === 0) {
            _.remove(
                newSelectedTickets,
                (t) =>
                    t.id === ticket.id &&
                    (t.sessionTime === undefined || t.sessionTime === ticket.sessionTime)
            );
        } else {
            newSelectedTickets = newSelectedTickets.map((t) => {
                return {
                    ...t,
                    quantity:
                        t.id === ticket.id &&
                        (t.sessionTime === undefined ||
                            t.sessionTime === ticket.sessionTime)
                            ? newQuantity
                            : t.quantity,
                };
            });
        }
        dispatch(setTickets(newSelectedTickets));
    };

    const onApply = async () => {
        if (formRef !== null && formRef.current !== null) {
            try {
                await emailForm.validateFields()
            } catch (err) {
                console.error(err)
                return
            }
            fetch(`${window.location.origin}/orders/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    tickets: selectedTickets,
                    clientMail: emailForm.getFieldValue('e-mail')
                })

            }).then(async (response) => {
                const parsed = await response.json();
                if (!response.ok) {
                    if (parsed.message === 'overLimit') {
                        parsed.errors.forEach((error: any) => {
                            message.error(`Залишилося лише ${error.ticket.quantity - error.overLimits} квитків ${error.ticket.title}
                            ${error.ticket.sessionTime
                                    ? `(${moment(error.ticket.sessionTime).format('HH:mm')})`
                                    : ''
                            }`)
                        })
                    } else {
                        message.error(parsed.message)
                    }
                    throw Error(parsed.message);
                }
                return parsed;
            }).then((order: IOrder) => {
                setOrderPrice(getSummaryPrice())
                setOrderId(order.id)
                setOrderSuccessURL(`${window.location.origin}/orders`)
                // @ts-ignore
                formRef.current.submit()
            }).catch((e) => {
                console.error(e)
                // message.error(e.message);
            })
        }
        return
    };

    const getButtonTooltipText = (): string => {
        const isGrotSelected = selectedTickets.find((ticket: ITicket) => ticket.title === 'Грот')
        const isExcursionsSelected = selectedTickets.find((ticket: ITicket) => ticket.title === 'Індивідуальна екскурсія')
        // if (isGrotSelected && !isExcursionsSelected && email === '') return "Будь ласка введіть вашу e-mail адресу, а також\n" +
        //     "додайте “Індивідуальну екскурсію” до вашого\n" +
        //     "замовлення - без неї неможливе відвідання гроту."
        if (isGrotSelected && !isExcursionsSelected) return "Будь ласка додайте “Індивідуальну екскурсію” до вашого\n" +
            "замовлення - без неї неможливе відвідання гроту."
        // if (email === '') return "Будь ласка введіть вашу e-mail адресу"
        return ""
    }

    const renderTickets = () => {
        return selectedTickets.map((ticket: ITicket) => {
            const sessionTime = ticket.sessionTime ? moment(ticket.sessionTime) : undefined
            let ticketType = `${ticket.costTypeTitle} квиток`;
            if (ticket.type !== 'entry') {
                switch (ticket.type) {
                    case 'excursion':
                        ticketType = TicketType.excursion;
                        break;
                    default:
                        break;
                }
            }
            return (
                <div className="item" key={ticket.id}>
                    <div>
                        <i
                            className="las la-times-circle remove"
                            role="button"
                            onClick={() => {
                                removeSelectedTicket(ticket);
                            }}
                        />
                        <div className="info">
                            <div className="title">{ticket.title}</div>
                            <div className="second-line">
                                <span>{ticketType}</span>
                                {sessionTime ? <>
                                    <span className="time-info"><i
                                        className="las la-calendar"/>{sessionTime.format('DD.MM.YYYY')}</span>
                                    {
                                        ticket.sessionSchedule && ticket.sessionSchedule.length > 0 ?
                                            <span className="time-info">
                                                <i className="las la-clock"/>{sessionTime.format('HH:mm')}</span> : ''}
                                </> : ''}
                            </div>
                        </div>
                    </div>
                    <div className='counter-wrapper'>
                        <div>
                            {ticket.type !== 'excursion' ? (
                                <div className="counter">
                                    <i
                                        className="lar la-minus-square"
                                        role="button"
                                        onClick={() => {
                                            minusTicket(ticket);
                                        }}
                                    />
                                    <span className="number">{ticket.quantity}</span>
                                    <i
                                        role="button"
                                        className="lar la-plus-square"
                                        onClick={() => {
                                            addTicketToCart(ticket);
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="counter">
                                    <i className="las la-minus fill-icon"/>
                                </div>
                            )}
                        </div>
                        <div className="price">{ticket.cost * ticket.quantity} ₴</div>
                    </div>
                </div>
            );
        });
    };

    const closeCart = () => {
        dispatch(setShowCart(false))
    }

    return (
        <CartStyled breakpoint={breakpoint} className={!showCart ? 'hidden' : ''} ref={cartRef}>
            <div className="wrapper">
                <div className="header">
                    <span>Ваше замовлення</span>
                    <i
                        className="las la-times-circle"
                        role="button"
                        onClick={() => {
                            closeCart();
                        }}
                    />
                    {/*<i */}
                    {/*  className="las la-times-circle" */}
                    {/*  role="button" */}
                    {/*  onClick={() => { */}
                    {/*    removeAllSelectedTickets(); */}
                    {/*  }} */}
                    {/*/> */}
                </div>
                {!selectedTickets || selectedTickets.length === 0 ? (
                    <div className='empty-msg-wrapper'>
                        <div className="empty-msg">
                            На жаль ви ще не додали
                            жодного квитка
                        </div>
                        <div className="no-tickets-img">
                            <img src="/no_tickets.svg" alt="tickets" width='48px' height='26px'/>
                        </div>
                    </div>
                ) : ''}
                <div className="items">{renderTickets()}</div>
            </div>
            {!selectedTickets || selectedTickets.length === 0 ? '' : (
                <div className="footer">
                <div className="summary">
                    <div className="summary-text">Загальна сума до сплати:</div>
                    <div className="summary-price">{getSummaryPrice()} ₴</div>
                </div>
                <div className="email-wrapper">
                    <span className="email-text">
                          Вкажіть e-mail на який буде відправлено ваше замовлення:
                    </span>
                    <Form
                        form={emailForm}
                        name="email-form"
                    >
                        <Form.Item
                            name="e-mail"
                            rules={[
                                {
                                    required: true,
                                    message: 'Будь ласка, введіть e-mail',
                                },
                                {
                                    type: 'email',
                                    message: 'Будь ласка, введіть правильний e-mail',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Form>
                </div>
                <Tooltip
                    placement="top"
                    title={getButtonTooltipText()}
                    color="rgba(39,36,36,0.8)"
                    overlayStyle={{
                        fontSize: '12px',
                    }}
                >
                    <div className="btn-wrapper">
                        <ButtonStyled
                            type="primary"
                            className="apply-btn"
                            size="large"
                            onClick={_.throttle(onApply, 2000)}
                            disabled={selectedTickets.length === 0 || getButtonTooltipText() !== ""}
                        >
                            Сплатити
                        </ButtonStyled>
                    </div>
                </Tooltip>
                <div className="made-by">
                <span>
                    Made with &#10084;&#65039; by <a href="https://eatery.club/" target='_blank'>Eatery Club</a>
                </span>
                </div>
            </div>
            )}
            {/* Variable REACT_APP_FE_NODE_ENV should be set at '.env' file*/}
            <form
                action={process.env.REACT_APP_FE_NODE_ENV !== 'development' ? "https://www.portmone.com.ua/gateway/" : `${window.location.origin}/staging-orders`}
                className="hidden" method="post" ref={formRef}>
                <input type="hidden" name="payee_id" value="25341"/>
                <input type="hidden" name="shop_order_number" value={orderId}/>
                <input type="hidden" name="bill_amount" value={orderPrice}/>
                <input type="hidden" name="description" value="Опис замовлення"/>
                <input type="hidden" name="success_url" value={orderSuccessURL}/>
                <input type="hidden" name="failure_url" value={orderFailureURL}/>
                <input type="hidden" name="lang" value="ua"/>
                <input type="hidden" name="encoding" value="UTF-8"/>
            </form>
            {/*<form action="https://www.portmone.com.ua/gateway/" method="post" ref={formRef}>*/}
            {/*    <input type="hidden" name="bodyRequest" value={dataToSend}/>*/}
            {/*    <input type="hidden" name="typeRequest" value='json'/>*/}
            {/*</form>*/}
        </CartStyled>
    );
}
