import styled from 'styled-components';
import { Button } from 'antd';

const ButtonStyled = styled(Button)`
  border-color: #272424;
  &:hover {
    color: #fe7062;
    border-color: #fe7062;
  }
  &:focus {
    color: #272424;
    border-color: #272424;
  }
  &.ant-btn-primary {
    background: #272424;
    border-color: #272424;
    &:focus {
      color: #ffffff;
    }
  }
  &[disabled],
  &[disabled]:hover {
    background: rgba(39, 36, 36, 0.25);
    border: 1px solid rgba(39, 36, 36, 0.25);
    color: #ffffff;
  }
`;

export default ButtonStyled;
