import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import * as _ from "lodash";
import moment from 'moment';
import 'moment/locale/uk';
import locale from 'antd/es/date-picker/locale/uk_UA.js';
import {Col, Form, Row, Select, Grid, message} from "antd";
import ITicket from "../../models/ITicket";
import SelectStyled from "../../styled/Select/Select";
import ITicketLimit from "../../models/ITicketLimit";
import ButtonStyled from "../../styled/Button/Button";
import {getCartShowing, getTickets, setShowCart, setTickets} from "../../store/cart";
import {DatePickerStyled, RangePickerStyled} from "../../styled/RangePicker/DatePicker";
import tickets from "../../data/tickets";
import {ScreenMap} from "antd/lib/_util/responsiveObserve";

const {Option} = Select;
const { useBreakpoint } = Grid;

const formatDate = (num: number) => {
    const formattedNumber = `0${num}`.slice(-2);
    return (formattedNumber);
};

interface ISelectOption {
    value: string;
    label: string;
}

interface TicketOrderStyledProps {
    breakpoint: ScreenMap
}

const TicketOrderStyled = styled.div<TicketOrderStyledProps>`
  margin-top: 48px;
  padding: 0 ${(props) => props.breakpoint.xs ? '16px' : 0};

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .form {
    margin-top: 48px;

    .form-item {
      &:not(:last-child) {
        padding-right: 16px;
      }
      &:first-child {
        padding-right: ${(props) => props.breakpoint.xs ? 0 : '16px'};
      }
    }
  }

  .price {
    display: flex;
    width: ${(props) => props.breakpoint.xs ? '100%' : 'inherit'};
    justify-content: ${(props) => props.breakpoint.xs ? 'space-between' : 'center'};
    font-size: 16px;

    span {
      font-weight: bold;
      margin-left: 8px;
    }
  }

  .controls {
    display: flex;
    justify-content: center;
    margin-top: 48px;
  }
  
  .footer {
    border-top: 1px solid #D9D8D8;
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: ${(props) => props.breakpoint.xs ? 'column' : 'row'};
  }

  .add-btn {
    font-size: 14px;
    padding: 8px 32px;
    height: inherit;
    font-weight: 600;
    margin-top: ${(props) => props.breakpoint.xs ? '24px' : '0'};
    width: ${(props) => props.breakpoint.xs ? '100%' : 'inherit'};

    .icon {
      margin-right: 8px;
    }
  }
`;

export default function TicketOrder(): JSX.Element {
    const [addForm] = Form.useForm();
    const {id} = useParams()
    const selectedTickets = useSelector(getTickets)
    const showCart = useSelector(getCartShowing)
    const dispatch = useDispatch();
    const breakpoint = useBreakpoint();
    const [ticketTypes, setTicketTypes] = useState<ITicket[]>([]);
    const [limits, setLimits] = useState<ITicketLimit[]>([]);
    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [typeOptions, setTypeOptions] = useState<ISelectOption[]>([]);
    const [timeOptions, setTimeOptions] = useState<ISelectOption[]>([]);
    const [selectType, setSelectType] = useState<string>('full');

    useEffect(() => {
        fetch(`${window.location.origin}/tickets/${id}`, {
            method: 'GET',
        }).then(async (response) => {
            const {tickets, limits} = await response.json()
            const ticket = tickets[0]
            setTicketTypes(tickets);
            setLimits(limits)
            setTitle(ticket.title)
            setDescription(ticket.description)
            setTypeOptions(tickets.map((t: ITicket) => {
                return {
                    value: t.costType,
                    label: t.costTypeTitle
                }
            }))
            if (ticket.sessionSchedule && ticket.sessionSchedule.length > 0) {
                // addForm.setFieldsValue({day: moment(ticket.sessionPeriod[0])})
                setTimeOptions(ticket.sessionSchedule.map((s: any) => {
                    return {
                        value: `${formatDate(s.hour)}:${formatDate(s.minutes)}`,
                        label: `${formatDate(s.hour)}:${formatDate(s.minutes)}`
                    }
                }))
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    const onAddFormSubmit = (fields: any) => {
        const ticket = ticketTypes.find((t) => t.costType === fields.costType)
        if (!ticket) return
        if (ticket.type === 'excursion') {
            const isExistedExcursion = selectedTickets.find(
                (t: ITicket) => t.id === ticket.id
            );
            if (isExistedExcursion) return;
        }
        let sessionTime: any;
        if (fields && (fields.hour || fields.day)) {
            const h = fields.hour ? fields.hour : '0:0';
            const [hour, minutes] = h.split(':');
            sessionTime = fields.day
                .hour(hour)
                .minute(minutes)
                .second(0)
                .millisecond(0)
                .valueOf();
        }
        if (!fields.hour && !fields.day) sessionTime = ticket.sessionTime;
        let newSelectedTickets = [...selectedTickets];
        const isExistedTicket = selectedTickets.find(
            (t: ITicket) =>
                t.id === ticket.id &&
                (t.sessionTime === undefined || t.sessionTime === sessionTime)
        );
        if (isExistedTicket) {
            newSelectedTickets = newSelectedTickets.map((t) => {
                const newQuantity = t.quantity + 1;
                return {
                    ...t,
                    quantity:
                        t.id === ticket.id &&
                        (t.sessionTime === undefined || t.sessionTime === sessionTime)
                            ? newQuantity
                            : t.quantity,
                };
            });
        } else {
            const sessionParams: any = {};
            if (ticket.sessionPeriod) {
                if (
                    ticket.sessionSchedule &&
                    ticket.sessionSchedule[0]?.limit !== undefined
                ) {
                    const d = moment(sessionTime);
                    const schedule = ticket.sessionSchedule.find(
                        (sch) => sch.hour === d.hour() && sch.minutes === d.minute()
                    );
                    if (!schedule) return;
                    sessionParams.sessionLimit = schedule.limit;
                }
                if (
                    ticket.sessionSchedule?.length === 0 &&
                    ticket.limit !== undefined
                ) {
                    sessionParams.sessionLimit = ticket.limit;
                }
                sessionParams.sessionTime = sessionTime;
            }
            newSelectedTickets.push({ ...ticket, quantity: 1, ...sessionParams });
        }
        dispatch(setTickets(newSelectedTickets));
        message.success(<span>Квиток доданий в <a onClick={() => {dispatch(setShowCart(true))}}>кошик</a></span>, 3)
    }

    const disabledDate = (current: any) => {
        const { sessionPeriod, weekdays } = ticketTypes[0];
        const isPastDate = current < moment().subtract(1, 'd').endOf('day')
        if (!sessionPeriod) return false;
        if (isPastDate) return true;
        const isDateInPeriod: boolean = (
            current.valueOf() < sessionPeriod[0] ||
            current.valueOf() > sessionPeriod[1]
        );

        if (!weekdays || !weekdays.length) return isDateInPeriod;
        const isWeekdayToDisable = !weekdays.some((isoDate: string) => {
            const dateFormat = 'YYYY-MM-DD';
            return moment(current).format(dateFormat) === moment(isoDate).format(dateFormat);
        });

        return isWeekdayToDisable;
    }


    const renderForm = (): JSX.Element => {
        const ticketType = ticketTypes[0];
        return (
            <Form
                form={addForm}
                name="add-form"
                layout="vertical"
                onFinish={onAddFormSubmit}
                initialValues={{
                    type: 'entry',
                }}
                fields={[
                    {
                        name: 'costType',
                    },
                    {
                        name: 'day',
                    },
                    {
                        name: 'sessionSchedule',
                    },
                ]}
            >
                <Row>
                    <Col sm={12} xs={24} className="form-item">
                        <Form.Item
                            name="costType"
                            initialValue='full'
                            rules={[
                                {
                                    required: true,
                                    message: 'Будь ласка, виберіть тип квитка',
                                },
                            ]}
                        >
                            <SelectStyled style={{width: '100%'}}
                                          onChange={(val) => setSelectType(val as string)}>
                                {typeOptions.map(({value, label}) => {
                                    return (<Option value={value}>{label} квиток</Option>)
                                })}
                            </SelectStyled>
                        </Form.Item>
                    </Col>
                    {ticketType && ticketType.sessionPeriod !== undefined ? (
                        <Col sm={6} xs={12} className="form-item">
                            <Form.Item name="day"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Будь ласка, оберіть дату',
                                           },
                                       ]}
                            >
                                <DatePickerStyled
                                    style={{width: '100%', height: '34px'}}
                                    disabledDate={disabledDate}
                                    format="DD.MM.YYYY"
                                    locale={locale}
                                    defaultPickerValue={moment(ticketType.sessionPeriod[0])}
                                    suffixIcon={<i className="las la-calendar"/>}
                                />
                            </Form.Item>
                        </Col>
                    ) : ''}
                    {ticketType && ticketType.sessionSchedule !== undefined && ticketType.sessionSchedule.length > 0 ? (
                        <Col sm={6} xs={12} className="form-item">
                            <Form.Item name="hour"
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Будь ласка, оберіть час',
                                           },
                                       ]}
                            >
                                <SelectStyled style={{width: '100%'}} placeholder="Оберіть час">
                                    {timeOptions.map(({value, label}) => {
                                        return (<Option value={value}>{label}</Option>)
                                    })}
                                </SelectStyled>
                            </Form.Item>
                        </Col>
                    ) : ''}
                </Row>
            </Form>
        )
    }

    const getPrice = () => {
        return ticketTypes.find((t) => t.costType === selectType)?.cost
    }

    return (<TicketOrderStyled breakpoint={breakpoint}>
        <Row>
            <Col xl={{ span: 12, offset: 6 }}
                 lg={{ span: 14, offset: 5 }}
                 md={{ span: 16, offset: 4 }}
                 sm={{ span: 22, offset: 1 }}
                 xs={24}>
                <div className="title">
                    {title}
                </div>
                <div className="description">
                    {description}
                </div>
                <div className="form">
                    {renderForm()}
                </div>
                <div className="footer">
                    <div className="price">
                        Вартість квитка: <span>{getPrice()}₴</span>
                    </div>
                    {/*<div className="controls">*/}
                        <ButtonStyled className="add-btn" key="back" type="primary" onClick={() => {
                            addForm.submit()
                        }}>
                            <i className="las la-plus icon"/> Додати до кошика
                        </ButtonStyled>
                    {/*</div>*/}
                </div>
            </Col>
        </Row>
    </TicketOrderStyled>)
}
