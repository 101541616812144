import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, message, Row} from "antd";
import * as _ from "lodash"
import moment from 'moment';
import { useHistory } from "react-router-dom";
import ITicket from "../../models/ITicket";
import Tickets from "../Tickets/Tickets";
import {useDispatch, useSelector} from "react-redux";
import {getTickets, setTickets} from "../../store/cart";
import Loader from "../Loader/Loader";

const HomeStyled = styled.div`
  background: #ffffff;
  .hidden {
    display: none;
  }
`;

export default function TicketsPage(): JSX.Element {

    const [tickets, setTicketsType] = useState<ITicket[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true)


    const selectedTickets = useSelector(getTickets)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect( () => {
        fetch(`${window.location.origin}/tickets`, {
            method: 'GET'
        }).then(async (response) => {
            let newTicketArr = await response.json()
            newTicketArr = _.sortBy(newTicketArr, 'title')
            const firstTickets = _.remove(newTicketArr, (t: ITicket) => t.title === 'Постійна експозиція')
            newTicketArr.unshift(...firstTickets)
            setTicketsType(newTicketArr);
            setIsLoading(false)
        }).catch((e) => {
            console.error(e)
        })
    }, []);

    const addTicketToCart = (ticket: ITicket) => {
        if (ticket.type === 'entry') {
            history.push(`ticket/${ticket.id}`)
            return;
        }
        if (ticket.type === 'excursion') {
            const isExistedExcursion = selectedTickets.find(
                (t: ITicket) => t.type === 'excursion'
            );
            if (isExistedExcursion) {
                message.error('Одночасно може бути вибраний тільки один тип екскурсії')
                return
            }
            dispatch(setTickets([...selectedTickets,  {...ticket, quantity: 1}]));
        }
    };

    return (
        <HomeStyled data-tid="container">
            <Row>
                <Col span={24}>
                    <Tickets
                        addTicketToCart={addTicketToCart}
                        tickets={tickets}
                        isExcursionsDisabled={
                            !!selectedTickets.find((t: ITicket) => t.type === 'excursion')
                        }
                    />
                </Col>
            </Row>
            <Loader show={isLoading}/>
        </HomeStyled>
    )
}
