import {combineReducers, createStore} from "redux";

import cart from "./cart"

const appReducer = combineReducers({
    cart
});

const store = createStore(
    appReducer
);

export default store;
